import { createForm } from '@/assets/api/form.js';

export default {
  data() {
    return {
      formData: {}
    };
  },
  methods: {
    async submitForm() {
      if (!this.$data.formData.name) {
        this.$notification.error({
          message: '请输入用户名'
        });
        return;
      }
      if (
        !this.$data.formData.phone ||
        !/^1{1}[0-9]{10}$/.test(this.$data.formData.phone)
      ) {
        this.$notification.error({
          message: '请输入正确的手机号'
        });
        return;
      }
      const resp = await createForm(this.formData);
      if (resp && resp.createForm) {
        this.formData = {};
        this.$notification.success({
          message: '提交成功！'
        });
      }
    }
  }
};
