<template>
  <div class="customer">
    <div class="banner">
      <img class="pc-show" :src="`${QNHost}/Customer/home_1.png`" alt="" />
      <img class="mobile-show" :src="`${QNHost}/Customer/mobile/1.png`" alt="">
      <div class="content">
        <div>
          <h1>加拿大1年快速移民通道</h1>
          <h2>简单<span>·</span>成功率高</h2>
          <p class="color-yellow">仅需雅思5分</p>
          <p class="color-yellow">投资合格的商业项目，占股10%起</p>
          <p class="space"></p>
          <p>无资金来源， 工作经验、管理经验硬性要求</p>
          <p>任选居住城市</p>
          <p class="tag">适合希望兼顾国内事业的申请人</p>
        </div>
      </div>
    </div>

    <div class="recommend">
      <img class="pc-show" :src="`${QNHost}/Customer/home_2.png`" alt="" />
      <img class="mobile-show" :src="`${QNHost}/Customer/mobile/2.png`" alt="">
      <div class="content">
        <div>
          <p>加拿大政府推崇的移民项目</p>
          <p class="small">移民审批通过率高</p>
          <p class="small">生意成败不影响获取枫叶卡</p>
          <img class="line" :src="`${QNHost}/Customer/home_line.png`" alt="">
          <div class="qrcode">
            <img :src="`${QNHost}/Customer/qrcode.png`" alt="" />
            <p>联<span>·</span>系<span>·</span>我<span>·</span>们</p>
          </div>
        </div>
      </div>
    </div>

    <div class="register">
      <form action="">
        <input type="text" v-model="formData.name" placeholder="您的姓名:" />
        <input type="text" v-model="formData.phone" placeholder="您的电话:" />
        <button type="button" @click="submitForm">领取专属移民定制方案</button>
      </form>
    </div>

    <div class="brief">
      <div>
        <h5>加拿大联邦创业移民（SUV）</h5>
        <h5>一步到位枫叶卡</h5>
        <p class="split-line">
          <img :src="`${QNHost}/Customer/home_3.png`" alt="">
          <img :src="`${QNHost}/Customer/home_3.png`" alt="">
          <img :src="`${QNHost}/Customer/home_3.png`" alt="">
        </p>
        <p>享免费医疗、优质教育资源</p>
        <p>可持TN签证，轻松赴美工作</p>
        <p>移民更移居</p>
      </div>
    </div>

    <div class="introduce">
      <div class="single">
        <p class="title pc-show">快</p>
        <img class="mobile-show" :src="`${QNHost}/Customer/mobile/fast.png`" alt="">
        <div>
          <h6>
            申请周期短<span class="pc-show"> ▶</span>
          </h6>
          <p>最快6个月登陆加拿大</p>
          <p>最快12个月获枫叶卡</p>
        </div>
      </div>
      <div class="single">
        <p class="title pc-show">简</p>
        <img class="mobile-show" :src="`${QNHost}/Customer/mobile/simple.png`" alt="">
        <div>
          <h6>
            要求简单 • 适应人群广<span class="pc-show"> ▶</span>
          </h6>
          <p>仅需雅思5分</p>
          <p>无需解释资金来源</p>
          <p>个人申请文件简单</p>
        </div>
      </div>
      <div class="single">
        <p class="title pc-show">高</p>
        <img class="mobile-show" :src="`${QNHost}/Customer/mobile/effective.png`" alt="">
        <div>
          <h6>
            移民审核通过率高<span class="pc-show"> ▶</span>
          </h6>
          <p>创业项目的商业属性成功与否</p>
          <p>不影响申请人的永久居留身份</p>
        </div>
      </div>
      <div class="single">
        <p class="title pc-show">省</p>
        <img class="mobile-show" :src="`${QNHost}/Customer/mobile/save.png`" alt="">
        <div>
          <h6>
            省时省心<span class="pc-show"> ▶</span>
          </h6>
          <p>对比其他传统移民大国<br class="mobile-show" />商业类移民项目</p>
          <p>SUV极具性价比</p>
          <p>一步到位枫叶卡</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import RecordMixin from '@/components/recordUser/recordMixin';

export default {
  mixins: [RecordMixin]
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
